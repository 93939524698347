import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, HashRouter } from "react-router-dom";
import ScrollToTop from "./views/ScrollToTop";


import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import HomePage from "views/HomePage/HomePage.js";
import MinervaConsumer from "views/WorkPage/MinervaConsumer.js";
import MinervaCorporate from "views/WorkPage/MinervaCorporate.js";
import egs from "views/WorkPage/Egs.js";
import hcoa from "views/WorkPage/Hcoa.js";
import bsc from "views/WorkPage/Bsc.js";
import acessa from "views/WorkPage/Acessa.js";
import aub from "views/WorkPage/Aub.js";
import about from "views/AboutPage/About.js";
import terms from "views/Terms.js";
import privacy from "views/Privacy.js";

var hist = createBrowserHistory();

function App() {

  // useEffect(() => {
  //   const imgs = [
  //     'src/assets/img/work/bsc/concepts2.png',
  //     'src/assets/img/work/bsc/disc-guide.png',
  //     'src/assets/img/work/bsc/header.png',
  //     'src/assets/img/work/bsc/iPhone-X1.png',
  //     'src/assets/img/work/bsc/iPhone-X2.png',
  //     'src/assets/img/work/bsc/iPhone-X3.png',
  //     'src/assets/img/work/bsc/laptop.png',
  //     'src/assets/img/work/bsc/longscreens-DBS.png',
  //     'src/assets/img/work/bsc/Stories.png',
  //     'src/assets/img/work/mv-dtc/102design_rollup_mockup_scene003.png',
  //     'src/assets/img/work/mv-dtc/ads.png',
  //     'src/assets/img/work/mv-dtc/emails-3.png',
  //     'src/assets/img/work/mv-dtc/header-minerva-consumer.jpg',
  //     'src/assets/img/work/mv-dtc/laptop.png',
  //     'src/assets/img/work/mv-dtc/longscreens-MV-DTC.jpg',
  //     'src/assets/img/work/mv-dtc/meet-minerva-screen-1.jpg',
  //     'src/assets/img/work/mv-dtc/meet-minerva-screen-2.jpg',
  //     'src/assets/img/work/mv-dtc/meet-minerva-screen-3.jpg',
  //     'src/assets/img/work/mv-dtc/meet-minerva-screen-4.jpg',
  //     'src/assets/img/work/mv-dtc/mv-dtc-iphone-1.png',
  //     'src/assets/img/work/mv-dtc/mv-dtc-iphone-2.png',
  //     'src/assets/img/work/mv-dtc/mv-dtc-iphone-3.png',
  //     'src/assets/img/work/mv-dtc/outreach-1.png',
  //     'src/assets/img/work/mv-dtc/Trifold-Brochure-Mock-Up.png',
  //     'src/assets/img/work/mv-dtc/TS-rough-mock-3.jpg',
  //     'src/assets/img/work/mv-dtc/woman-hand-iphone-email.jpg',
  //     'src/assets/img/work/mv-dtc/YOGA-beginning-2.jpg',
  //     'src/assets/img/work/mv-hcp/A4_Brochure_Mockup_1.png',
  //     'src/assets/img/work/mv-hcp/A4_Brochure_Mockup_5sm.png',
  //     'src/assets/img/work/mv-hcp/header.png',
  //     'src/assets/img/work/mv-hcp/iPad-Pro.png',
  //     'src/assets/img/work/mv-hcp/laptop.png',
  //     'src/assets/img/work/mv-hcp/longscreens-MVhcp.jpg',
  //     'src/assets/img/work/mv-hcp/Postcard.png',
  //     'src/assets/img/work/hcoa/brochure.png',
  //     'src/assets/img/work/hcoa/header.png',
  //     'src/assets/img/work/hcoa/iPhone-X1.png',
  //     'src/assets/img/work/hcoa/iPhone-X2.png',
  //     'src/assets/img/work/hcoa/iPhone-X3.png',
  //     'src/assets/img/work/hcoa/laptop.png',
  //     'src/assets/img/work/hcoa/longscreens-HCOA.png',
  //     'src/assets/img/work/egs/header.png',
  //     'src/assets/img/work/egs/iPhone-X1.png',
  //     'src/assets/img/work/egs/iPhone-X2.png',
  //     'src/assets/img/work/egs/iPhone-X3.png',
  //     'src/assets/img/work/egs/laptop.png',
  //     'src/assets/img/work/egs/longscreens-EGS.png',
  //     'src/assets/img/work/egs/questions.png',
  //   ];

  //   cacheImages(imgs);
  // }, []);

  // const cacheImages = (srcArray) => {
  //   srcArray.map((src) => {
  //     const img = new Image();
  //     img.src = src;
  //   });
  // }

  return (
    
    <HashRouter hashType="slash" history={hist}>
      <ScrollToTop>
        <Switch>
          <Route path="/our-work/minerva-consumer" component={MinervaConsumer} />
          <Route path="/our-work/minerva-corporate" component={MinervaCorporate} />
          <Route path="/our-work/gerdhelp" component={egs} />
          <Route path="/our-work/hcoa" component={hcoa} />
          <Route path="/our-work/dbs" component={bsc} />
          <Route path="/our-work/acessa" component={acessa} />
          <Route path="/our-work/aub" component={aub} />
          <Route path="/about" component={about} />
          <Route path="/terms" component={terms} />
          <Route path="/privacy" component={privacy} />
          <Route path="/" component={HomePage} />
          <Route path="/index" component={HomePage} />
        </Switch>
      </ScrollToTop>
    </HashRouter>
  )
  
}

export default App;


