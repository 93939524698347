import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { NavHashLink as Link } from 'react-router-hash-link';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/diffStyle.js";

const useStyles = makeStyles(styles);

export default function DiffSection() {
  const classes = useStyles();
  const animLeft = React.createRef();
  const animRight = React.createRef();

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#animation",
        start: "top 90%",
        end: "top 60%",
        scrub: 1
      }
    });
    tl.from(animLeft.current, {x: -1200, opacity: 0, duration: 4, scale: 0, ease: "power3.out"});
    tl.from(animRight.current, {x: +1200, opacity: 0, duration: 4, scale: 0, ease: "power3.out"}, '-=4');
  }, [])

  return (
    <div id="difference" className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="space-evenly" className={classes.containerDescriptionFirst}>
          <GridItem xs={12} sm={10} md={8}>
            <h1 className={classes.title}>Snackable &amp; actionable</h1>
            <h4>Transforming complex structures and information into inspirational and actionable experiences</h4>
            <p className={classes.description}>
            You don’t need us to tell you that healthcare is complex. Staying connected to all your key audiences is like trying to hug butterflies. Think of us as your connector. We create beautiful pathways that are easy to navigate and bring patients and healthcare professionals together to have productive conversations that lead to solutions.
            </p>
          </GridItem>
        </GridContainer>
        
        <GridContainer justify="space-evenly" className={classes.containerDescription}>
          <GridItem xs={12} sm={10} md={8}>
          <h1 className={classes.title}>Chemistry that works</h1>
          <p>As veterans of successful, large advertising agencies, we’ve learned the secret to <Link to="#work" smooth>work that works</Link>. It has everything to do with what you don’t add. So we ditched the layers and hierarchy for a virtual in-house relationship with our clients.</p>
          <p>Enjoy collaborating directly with the creative team doing your work, rather than random account people. You’ll have your own focused, nimble team to master your business, goals and culture. We’ll accomplish your mission with as little drama as possible.</p>
          <div id="animation">
            <div ref={animLeft} className="anim-left">
              <ul>
                <li>Branding, Identity &amp; Voice</li>
                <li>Digital Experiences</li>
                <li>Website Development</li>
                <li>Consumer Offline Engagement</li>
              </ul>
            </div>
            <div ref={animRight} className="anim-right">
              <ul>
                <li>Sales Productivity Tools</li>
                <li>Animation &amp; Video Production</li>
                <li>Print &amp; Direct Mail</li>
                <li>Conference Production</li>
              </ul>
            </div>
          </div>
          </GridItem>
        </GridContainer>

        <GridContainer justify="space-evenly" className={classes.containerDescription}>
          <GridItem xs={12} sm={10} md={8}>
          <h1 className={classes.title}>What makes work work?</h1>
          <p>In healthcare, it’s deeply empathic communication, attention to detail and transparency. All taking place inside an irresistible and respectful experience.</p>
          <p>Our strength is bringing together patients, healthcare professionals and medical device innovators to solve patient problems, leaving them healthy and completely satisfied.</p>
          <p>It’s 2020+. Healthcare consumers (patients and providers) spot and reject marketing faster than you can summon Alexa.</p>
          <p>They told us they need trustworthy information. Stop selling them. They want to solve their problem as much as anyone. Partner with them. Get to know them. Inform them with digestible technical insight and clinical data. Focus on their needs. And be accessible.</p>
          <p>In other words, be a health ally. We help you do that.</p>
          </GridItem>
        </GridContainer>


      </div>
    </div>
  );
}
