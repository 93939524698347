import React from "react";
// nodejs library that concatenates classes

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import SectionContactUs from "../HomePage/Sections/SectionContactUs";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/workPage.js";

import laptop from "assets/img/work/acessa/laptop.png";
import landing from "assets/img/work/acessa/landing.jpg";
import print1 from "assets/img/work/acessa/print-1.png";
import print2 from "assets/img/work/acessa/print-2.png";
import emails from "assets/img/work/acessa/emails.png";
import videos from "assets/img/work/acessa/videos.png";
import ads1 from "assets/img/work/acessa/728x90-2.gif";
import ads2 from "assets/img/work/acessa/300x600-1.gif";
import ads3 from "assets/img/work/acessa/300x600-2b.gif";
import ads4 from "assets/img/work/acessa/300x250-1.gif";
import titleAds from "assets/img/work/acessa/ads.png";
import headerImg from "assets/img/work/acessa/header.jpg";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const mainClasses = classNames({
    [classes.main]: true,
    [classes.acessa]: true
  });
  const { ...rest } = props;
  
  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax className="acessa" small image={headerImg}>
        <div className="portfolio-client-name acessa">
          <span>A</span>
          <span>C</span>
          <span>E</span>
          <span>S</span>
          <span>S</span>
          <span>A</span>
        </div>
        <div className="portfolio-title acessa">
          
        </div>
      </Parallax>
      <div className={mainClasses}>
        <div>
          <div className={classes.container}>
            <h1 className={classes.title}>Acessa</h1>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={8}>
              <p>Far too many women are told they have fibroids and that 'it's okay'.</p>
              <p>No. It's not okay.</p>
              <p>In this DTC website, we empowered women with the tools and knowledge needed to effectively navigate the path to treatment that is best for them, not their gynecologist. We didn't gloss over the technical aspects of the Acessa procedure, because women can handle it. We provided them with the option of having a free nurse consult so they can have an unrushed conversation that didn't begin with getting on a scale and getting naked. And then we connected them with physicians trained and experienced in the Acessa procedure. At every juncture in the path to treatment, we held her hand. Until she didn't need us anymore, because she was fibroid-free.</p>
              </GridItem>
            </GridContainer>
          </div>
          <div className={classes.container}>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={12}>
                <img alt="" src={laptop} />
              </GridItem>
            </GridContainer>
          </div>

          <div className="vertical-holder landing top">
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <GridItem xs={12} sm={12} md={10}>
                  <ScrollAnimation animateIn="animate__fadeInLeft">
                    <div className="item concept"><img alt="" src={landing} /></div>
                  </ScrollAnimation>
                </GridItem>
              </GridContainer>
            </div>
          </div>
          <div className="vertical-holder print top">
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <div className="item brochure"><img alt="" src={print1} /></div>
              </GridContainer>
            </div>
            <div className={classes.container} style={{marginTop:'0px'}}>
              <GridContainer justify="center" className="flex-center">
                <div className="item brochure"><img alt="" src={print2} /></div>
              </GridContainer>
            </div>
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <div className="item brochure"><img alt="" src={emails} /></div>
              </GridContainer>
            </div>
          </div>
          <div className={classes.container} style={{marginTop:'100px'}}>
            <GridContainer justify="center" className="flex-center">
              <div className="item brochure"><img alt="" src={videos} /></div>
            </GridContainer>
          </div>
          <div className={classes.container} style={{marginTop:'100px'}}>
            <GridContainer justify="center" className="ads ads-np">
              <img alt="" src={titleAds} />
              <div className="item ad"><img alt="" src={ads1} /></div>
            </GridContainer>
            <GridContainer justify="center" className="ads">
              <div className="item ad"><img alt="" src={ads2} /></div>
              <div className="item ad"><img alt="" src={ads3} /></div>
              <div className="item ad"><img alt="" src={ads4} /></div>
            </GridContainer>
          </div>
          
          <div className={classes.container}>
            <SectionContactUs />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
