import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import SectionContactUs from "../HomePage/Sections/SectionContactUs";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/workPage.js";

import laptop from "assets/img/work/bsc/laptop.png";
import iphone1 from "assets/img/work/bsc/iPhone-X1.png";
import iphone2 from "assets/img/work/bsc/iPhone-X2.png";
import iphone3 from "assets/img/work/bsc/iPhone-X3.png";
import concepts1 from "assets/img/work/bsc/concepts1.png";
import concepts2 from "assets/img/work/bsc/concepts2.png";
import longScreens from "assets/img/work/bsc/longscreens-DBS.png";
import discGuide from "assets/img/work/bsc/disc-guide.png";
import stories from "assets/img/work/bsc/Stories.png";
import headerImg from "assets/img/work/bsc/header.png";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const mainClasses = classNames({
    [classes.main]: true,
    [classes.bsc]: true
  });
  const { ...rest } = props;
  
  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax className="bsc" small image={headerImg}>
        <div className="portfolio-client-name bsc">
          <span>D</span>
          <span>B</span>
          <span>S</span>
        </div>
        <div className="portfolio-title bsc">
          BOSTON SCIENTIFIC DEEP BRAIN STIMULATION
        </div>
      </Parallax>
      <div className={mainClasses}>
        <div>
          <div className={classes.container}>
            <h1 className={classes.title}>Boston Scientific Deep Brain Stimulation</h1>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={8}>
              <p>The DBS team at Boston Scientific needed a way to communicate the customizable treatment of movement disorders to consumers.</p>
              </GridItem>
            </GridContainer>
          </div>
          <div className={classes.container}>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={12}>
                <img alt="" src={laptop} />
              </GridItem>
            </GridContainer>
          </div>

          <div className="vertical-holder concepts top">
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <GridItem xs={12} sm={12} md={10}>
                  <div className="item concept"><img alt="" src={concepts1} /></div>
                  <div className="item concept"><img alt="" src={concepts2} /></div>
                </GridItem>
              </GridContainer>
            </div>
          

            <div className="vertical-holder story">
              <div className={classes.container} style={{marginTop:'100px'}}>
                <GridContainer justify="center" className="flex-center">
                  <div className="item longscreens"><img alt="" src={longScreens} /></div>
                </GridContainer>
              </div>
            </div>
          </div>

          <div className={classes.container} style={{marginTop:'100px'}}>
            <GridContainer justify="center" className="flex-center">
              <div className="item iphone"><img alt="" src={iphone1} /></div>
              <div className="item iphone"><img alt="" src={iphone2} /></div>
              <div className="item iphone"><img alt="" src={iphone3} /></div>
            </GridContainer>
          </div>

          <div className="vertical-holder print top">
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <div className="item brochure"><img alt="" src={discGuide} /></div>
              </GridContainer>
            </div>
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <div className="item brochure"><img alt="" src={stories} /></div>
              </GridContainer>
            </div>
          </div>
          
          
          <div className={classes.container}>
            <SectionContactUs />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
