import { container, section,title } from "assets/jss/material-kit-react.js";

const carouselStyle = {
  section,
  container,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  },
  title: {
    ...title,
    marginTop: "20px",
    textAlign: "right"
  }

};

export default carouselStyle;
