import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import SectionContactUs from "../HomePage/Sections/SectionContactUs";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import '../video.css';
import VideoPlayer from "../VideoPlayer";

import styles from "assets/jss/material-kit-react/views/workPage.js";

import laptop from "assets/img/work/aub/laptop.png";
import longScreens from "assets/img/work/aub/longscreens-story.jpg";
import iphones from "assets/img/work/aub/iphone-screens.png";
import rollup from "assets/img/work/aub/rollup_mockup.png";
import discussionguide from "assets/img/work/aub/discussionguide.png";
import awarenessTrifold from "assets/img/work/aub/awareness-trifold.png";
import brochureMockup from "assets/img/work/aub/brochure-mockup-all.png";
import firtCover from "assets/img/work/aub/fertility-cover-inside.png";
import tabletop from "assets/img/work/aub/tabletop-mockup.png";
import socialIphones from "assets/img/work/aub/social-iphones.png";
import ninexaub from "assets/img/work/aub/9xposts-aub.jpg";
import events from "assets/img/work/shared/events.png";
import events1 from "assets/img/work/aub/event-1.jpg";
import events2 from "assets/img/work/aub/event-2.jpg";
import identity from "assets/img/work/shared/identity.png";

import headerImg from "assets/img/work/aub/aub-header.jpg";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const mainClasses = classNames({
    [classes.main]: true,
    [classes.aub]: true
  });
  const { ...rest } = props;

  return (
      <div>
        <Header
            color="white"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 400,
              color: "white"
            }}
            {...rest}
        />
        <Parallax className="aub" small image={headerImg}>
          <div className="portfolio-client-name aub">
            <span>M</span>
            <span>I</span>
            <span>N</span>
            <span>E</span>
            <span>R</span>
            <span>V</span>
            <span>A</span>
          </div>
          <div className="portfolio-title aub">
            MINERVA CONSUMER
          </div>
        </Parallax>
        <div className={mainClasses}>
          <div>
            <div className={classes.container}>
              <h1 className={classes.title}>Minerva consumer</h1>
              <GridContainer justify="flex-start">
                <GridItem xs={12} sm={12} md={8}>
                  <p>Minerva delivered much needed support and education for people living with heavy, painful periods by investing in a comprehensive awareness website, in-person events and supporting assets focused on AUB (abnormal uterine bleeding). AUB and Me unwinds misinformation around symptoms that are often ignored or dismissed as "bad periods" but are signs of common, chronic conditions like fibroids, adenomyosis and PCOS. Deep dives into AUB, diagnostic path, treatment options and even a much-needed tour of the uterus lift patient health and body literacy for improved consultations. Engagement on the website averaged 8 minutes. Female patients are desperate for accurate information served up with a hefty dose of empathy, and since that's our super power, we were ready to delight them. </p>
                </GridItem>
              </GridContainer>
            </div>
            <div className={classes.container}>
              <GridContainer justify="flex-start">
                <GridItem xs={12} sm={12} md={12}>
                  <img alt=""  src={laptop} />
                </GridItem>
              </GridContainer>
            </div>

            <div className="vertical-holder top story">
              <div className={classes.container} style={{marginTop:'100px'}}>
                <GridContainer justify="center" className="flex-center">
                  <div className="item longscreens"><img alt=""  src={longScreens} /></div>
                </GridContainer>
              </div>
            </div>

            <div className="vertical-holder">
              <div className={classes.container} style={{marginTop:'100px'}}>
                <GridContainer justify="center" className="flex-center">
                  <GridItem xs={12} sm={12} md={12}>
                    <img alt=""  src={iphones} />
                  </GridItem>
                </GridContainer>
              </div>
            </div>


            <div className="vertical-holder consumer-education top">
              <div className={classes.container} style={{marginTop:'100px'}}>
                <GridContainer justify="center" className="flex-center">
                  <div className="item stretch"><img alt=""  src={rollup} /></div>
                  <div className="item stretch"><img alt=""  src={discussionguide} /></div>
                  <div className="item stretch"><img alt=""  src={awarenessTrifold} /></div>
                  <div className="item stretch"><img alt=""  src={brochureMockup} /></div>
                  <div className="item stretch"><img alt=""  src={firtCover} /></div>
                  <div className="item stretch"><img alt=""  src={tabletop} /></div>
                </GridContainer>
              </div>
            </div>

            <div className="vertical-holder social top">
              <div className={classes.container} style={{marginTop:'100px'}}>
                <GridContainer justify="center" className="flex-center">
                  <div className="item social-phones stretch"><img alt=""  src={socialIphones} /></div>
                </GridContainer>
              </div>
            </div>

            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <div className="item stretch ninexaub"><img alt=""  src={ninexaub} /></div>
              </GridContainer>
            </div>

            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <div className="item stretch"><img alt=""  src={events} /></div>
              </GridContainer>
            </div>
            <div className={classes.container} style={{marginTop:'30px'}}>
              <GridContainer justify="center" className="flex-center">
                <div className="item event1"><img alt=""  src={events1} /></div>
                <div className="item event2"><img alt=""  src={events2} /></div>
              </GridContainer>
            </div>

            <div className={classes.container} style={{marginTop:'150px', marginBottom: '150px'}}>
              <GridContainer justify="center" className="flex-center">
                <div className="item stretch"><img alt=""  src={identity} /></div>
                <VideoPlayer />
              </GridContainer>
            </div>


            <div className={classes.container}>
              <SectionContactUs />
            </div>
          </div>
        </div>
        <Footer />
      </div>
  );
}
