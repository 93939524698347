import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
// Sections for this page
import DiffSection from "./Sections/DiffSection.js";
import SectionCarousel from "./Sections/SectionCarousel";
import SectionContactUs from "./Sections/SectionContactUs";

import { NavHashLink as Link } from 'react-router-hash-link';
import logoLeft from "assets/img/marquee/logo-left.png";
import logoRight from "assets/img/marquee/logo-right.png";
import logoAdvAg from "assets/img/marquee/logo-adv-agency.png";

import img1 from "assets/img/marquee/TNAA-marquee-neon1.jpg";
import img2 from "assets/img/marquee/TNAA-marquee-neon2.jpg";
import img3 from "assets/img/marquee/TNAA-marquee-neon3.jpg";
import img4 from "assets/img/marquee/TNAA-marquee-neon4.jpg";
import img5 from "assets/img/marquee/TNAA-marquee-neon5.jpg";
import img6 from "assets/img/marquee/TNAA-marquee-neon6.jpg";



const useStyles = makeStyles(styles);

export default function HomePage(props) {

  const refLogoLeft = React.createRef();
  const refLogoRight = React.createRef();
  const refLogoAdvAg = React.createRef();
  const tagline1 = React.createRef();
  const tagline2 = React.createRef();
  const tagline3 = React.createRef();
  const content = React.createRef();

  // gsap.registerPlugin(ScrollTrigger);
  // const tl = gsap.timeline({
  //   scrollTrigger: {
  //     trigger: "#hero",
  //     start: "top top",
  //     end: "bottom top",
  //     scrub: true
  //   }
  // });
  const tl2 = gsap.timeline();

  useEffect(() => {
    // gsap.utils.toArray(".home-parallax").forEach(layer => {
    //   const movement = -(layer.offsetHeight)
    //   tl.to(layer, {y: movement, ease: "none"}, 0)
    // });
    tl2.from(refLogoLeft.current, {x: -1200, opacity: 0, duration: 2, scale: 0, ease: "power3.out"});
    tl2.from(refLogoRight.current, {x: +1200, opacity: 0, duration: 2, scale: 0, ease: "power3.out"}, '-=2');
    tl2.from(refLogoAdvAg.current, {opacity: 0, scale: 0.1, duration: 1}, '-=1');
    tl2.from(tagline1.current, {opacity: 0, scale: 0.1, duration: 0.5}, '+=0.1');
    tl2.from(tagline2.current, {opacity: 0, scale: 0.1, duration: 0.5}, '+=0.1');
    tl2.from(tagline3.current, {opacity: 0, scale: 0.1, duration: 0.5}, '+=0.1');
    tl2.from(content.current, {opacity: 0}, '+=0.75');
  }, [])

  
  
  const classes = useStyles();
  const { ...rest } = props;

  const logoLeftImg = <img src={logoLeft} alt="Advocats Agency" className="logo-left" ref={refLogoLeft} />;
  const logoRightImg = <img src={logoRight} alt="Advocats Agency" className="logo-right" ref={refLogoRight} />;
  const logoAdvAgImg = <img src={logoAdvAg} alt="Advocats Agency" className="logo-adv-ag" ref={refLogoAdvAg} />;

  const downArrow = <Link className="down-arrow down" smooth to={'/#difference'}></Link>;

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />

      <div className="marquee fadein" id="hero">
        <img id="f2" alt=""  src={img2} />
        <img id="f3" alt=""  src={img3} />
        <img id="f4" alt=""  src={img4} />
        <img id="f6" alt=""  src={img6} />

        <div className='logo-wrapper'>
          {logoLeftImg}
          {logoRightImg}
        </div>
        <div className="logo-wrapper-advag">{logoAdvAgImg}</div>
        <div className='tagline'>
          <span ref={tagline1}>EDUCATE.</span>
          <span ref={tagline2}>ADVOCATE.</span>
          <span ref={tagline3} className='last'>EMPOWER.</span>
        </div>
        <div className='home-marquee-content' ref={content}>
        Advocats unites consumers, healthcare professionals, health systems and health innovators through awareness and education campaigns. So patients heal and communities thrive.
        </div>
        {downArrow}

      </div>

      {/* <div id='hero'>
        <div className='home-layer-bg home-layer home-parallax'></div>
        <div className='logo-wrapper'>
          {logoLeftImg}
          {logoRightImg}
        </div>
        {logoAdvAgImg}
        <div className='tagline'>
          <span ref={tagline1}>EDUCATE.</span>
          <span ref={tagline2}>ADVOCATE.</span>
          <span ref={tagline3} className='last'>EMPOWER.</span>
        </div>
        <div className='home-marquee-content' ref={content}>
        Advocats unites consumers, healthcare practitioners, hospital systems and medical technology companies. So patients heal and communities thrive.
        </div>
        {downArrow}
      </div> */}
      
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <DiffSection />
          <SectionCarousel />
          <SectionContactUs />
        </div>
      </div>
      <Footer />
    </div>
  );
}
