import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import SectionContactUs from "../HomePage/Sections/SectionContactUs";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/workPage.js";

import laptop from "assets/img/work/mv-dtc/laptop.png";
import social1 from "assets/img/work/mv-dtc/YOGA-beginning-2.jpg";
import social2 from "assets/img/work/mv-dtc/meet-minerva-screen-1.jpg";
import social3 from "assets/img/work/mv-dtc/meet-minerva-screen-2.jpg";
import social5 from "assets/img/work/mv-dtc/meet-minerva-screen-3.jpg";
import social4 from "assets/img/work/mv-dtc/meet-minerva-screen-4.jpg";
import brochure from "assets/img/work/mv-dtc/Trifold-Brochure-Mock-Up.png";
import longScreens from "assets/img/work/mv-dtc/longscreens-MV-DTC.jpg";
import iphone1 from "assets/img/work/mv-dtc/mv-dtc-iphone-1.png";
import iphone2 from "assets/img/work/mv-dtc/mv-dtc-iphone-2.png";
import iphone3 from "assets/img/work/mv-dtc/mv-dtc-iphone-3.png";
import outreach1 from "assets/img/work/mv-dtc/outreach-1.png";
import ads from "assets/img/work/mv-dtc/ads.png";
import rollup from "assets/img/work/mv-dtc/102design_rollup_mockup_scene003.png";
import stand from "assets/img/work/mv-dtc/TS-rough-mock-3.jpg";
import headerImg from "assets/img/work/mv-dtc/header-minerva-consumer.jpg";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const mainClasses = classNames({
    [classes.main]: true,
    [classes.minervaConsumer]: true
  });
  const { ...rest } = props;
  
  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax className="minerva-consumer" small image={headerImg}>
        <div className="portfolio-client-name">
          <span>M</span>
          <span>I</span>
          <span>N</span>
          <span>E</span>
          <span>R</span>
          <span>V</span>
          <span>A</span>
        </div>
        <div className="portfolio-title">
          MINERVA CONSUMER
        </div>
      </Parallax>
      <div className={mainClasses}>
        <div>
          <div className={classes.container}>
            <h1 className={classes.title}>Minerva consumer</h1>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={8}>
              <p>A best-in-class treatment for women suffering from abnormal uterine bleeding (AUB) is overshadowed by an inferior competitor’s market share lead. Secret shops of medical practices uncovered little knowledge of the treatment at the front desk, leaving the consumer to figure it out on their own. Market research showed women were frustrated by the lack of information and education on non-hormonal treatments for their periods. So we got to work.</p>
              </GridItem>
            </GridContainer>
          </div>
          <div className={classes.container}>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={12}>
                <img alt=""  src={laptop} />
              </GridItem>
            </GridContainer>
          </div>
          <div className={classes.container}>
            <div className="portfolio-sub-title">
              SOCIAL
            </div>
            <GridContainer justify="center" className="flex-center">
              <div className="item"><img alt=""  src={social1} /></div>
              <div className="item"><img alt=""  src={social2} /></div>
              <div className="item"><img alt=""  src={social3} /></div>
              <div className="item"><img alt=""  src={social4} /></div>
              <div className="item"><img alt=""  src={social5} /></div>
            </GridContainer>
          </div>
          
          <div className="vertical-holder print">
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <div className="item brochure"><img alt=""  src={brochure} /></div>
              </GridContainer>
            </div>
          </div>
          
          <div className="vertical-holder story">
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <div className="item longscreens"><img alt=""  src={longScreens} /></div>
              </GridContainer>
            </div>
          </div>

          <div className={classes.container} style={{marginTop:'100px'}}>
            <GridContainer justify="center" className="flex-center">
              <div className="item iphone"><img alt=""  src={iphone1} /></div>
              <div className="item iphone"><img alt=""  src={iphone2} /></div>
              <div className="item iphone"><img alt="" src={iphone3} /></div>
            </GridContainer>
          </div>

          <div className="vertical-holder outreach top">
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <GridItem xs={12} sm={8} md={12}>
                  <img alt=""  src={outreach1} />
                </GridItem>
              </GridContainer>
            </div>
            <div className={classes.container}>
              <div className="portfolio-sub-title">
                ADS
              </div>
              <GridContainer justify="center" className="flex-center">
                <GridItem xs={12} sm={8} md={12}>
                  <img alt=""  src={ads} />
                </GridItem>
              </GridContainer>
            </div>
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <GridItem xs={12} sm={12} md={12}>
                  <img alt=""  src={rollup} />
                </GridItem>
              </GridContainer>
            </div>
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <GridItem xs={12} sm={12} md={12}>
                  <img alt=""  src={stand} />
                </GridItem>
              </GridContainer>
            </div>
          </div>
          <div className={classes.container}>
            <SectionContactUs />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
