import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import carouselStyle from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
import basicsStyle from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

import minervaConsumer from "assets/img/work/carousel/mv-dtc.jpg";
import minervaCorporate from "assets/img/work/carousel/mv-hcp.jpg";
import egs from "assets/img/work/carousel/egs.jpg";
import dbs from "assets/img/work/carousel/dbs.jpg";
import hcoa from "assets/img/work/carousel/hcoa.jpg";
import acessa from "assets/img/work/carousel/acessa.jpg";
import aub from "assets/img/work/carousel/aub.jpg";

const carouselClassStyle = makeStyles(carouselStyle);
const formClassStyle = makeStyles(basicsStyle);

export default function SectionCarousel() {
  const classes = carouselClassStyle();
  const formClasses = formClassStyle();
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const handleSimple = event => {
    setSimpleSelect(event.target.value);
    window.location = "/our-work/" + event.target.value;
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  const clients = [
    {client: "Abnormal Uterine Bleeding", link: "aub", image: aub, key: "aub"},
    {client: "Minerva Consumer", link: "minerva-consumer", image: minervaConsumer, key: "minervaConsumer"},
    {client: "Minerva Corporate", link: "minerva-corporate", image: minervaCorporate, key: "minervaCorporate"},
    {client: "Gerdhelp", link: "gerdhelp", image: egs, key: "egs"},
    {client: "Hernia Centers of America", link: "hcoa", image: hcoa, key: "hcoa"},
    {client: "Boston Scientific DBS", link: "dbs", image: dbs, key: "dbs"},
    {client: "Acessa", link: "acessa", image: acessa, key: "acessa"}
  ];
  const quickLinks = clients.map((prop, key) => {
    return (
      <Link
          to={"/our-work/" + prop.link}
        >
        {prop.client}
        </Link>
    // <a href={"our-work/" + prop.link} className={classes.dropdownLink}>{prop.client}</a>
    );
  });
  const carouselItems = clients.map((prop, key) => {
    return (
      <div key={key}>
        <Link
          to={"/our-work/" + prop.link}
        >
          <img src={prop.image} alt={"Our work for " + prop.client} className="slick-image" />
        </Link>
      </div>
    );
  });
  
  return (
    <div className={classes.section} id="work">
      <h1 className={classes.title}>See our work working</h1>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} className="quick-links">
          <CustomDropdown
            dropdownHeader=""
            buttonText="Projects quick link"
            buttonProps={{
              round: false,
              block: false,
              color: "white",
              size: "sm"
            }}
            dropPlacement="bottom"
            dropdownList={quickLinks}
          />
        </GridItem>
      </GridContainer>
      <Card className="carousel-portfolio">
        <Carousel {...settings}>
          {carouselItems}
        </Carousel>
      </Card>
        
    </div>
  );
}
