import React from "react";
// nodejs library that concatenates classes

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/workPage.js";


const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const mainClasses = classNames({
    [classes.main]: true,
    [classes.acessa]: true
  });
  const { ...rest } = props;
  
  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax className="level3" level3 image={require("assets/img/level3/header.jpg")}>
        
      </Parallax>
      <div className={`${mainClasses} ${"padding-bottom-big content-container"}`}>
        <div>
          <div className={classes.container} >
            <h1 className={classes.title}>Privacy Policy</h1>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={8}>
              <p>We are committed to protecting your privacy and security online. This page describes the type of information we collect, how we use it and how we keep it secure, including our use of cookies.</p>
              
<h2>Personally identifiable information</h2>
<p>We only collect and store Personally Identifiable Information about you if it is voluntarily submitted. Personally Identifiable Information may include your name, email address, physical address and other information that specifically identifies you. Any information we collect will only be used within Advocats and it will not be shared with other organizations.</p>
 
<p>Advocats is fully GDPR and CCPA compliant. See below for how to manage your rights under these regulations.</p>
 
<h2>Other types of information</h2>
<p>We may collect and store anonymous information about your use of this website. Examples of this type of information include demographic information, the type of internet browser you are using, the type of computer operating system, application software and peripherals you are using, the domain name of the web site from which you linked to our site and your browsing habits.</p>
 
<h2>Cookies</h2>
<p>This site uses cookies. The first time you visit we assign an ID which is stored as a cookie on your computer. Cookies do not contain any personal information about you. We collect this information solely to learn more about the visitors to the different sections of our site and to help make your website experience more useful and informative. We do not share such information with anyone else.</p>
 
<p>You may disable cookies within your browser. Follow these links for further information:</p>
<ul>
  <li>Chrome: https://support.google.com/chrome/answer/95647?hl=en</li>
  <li>Internet Safari: http://help.apple.com/safari/mac/8.0/#/sfri11471</li>
  <li>Explorer: http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies</li>
</ul>
 
<h2>Security</h2>
<p>We are committed to high standards of information security. We have appropriate policies and procedures in place to prevent unauthorized access, maintain data accuracy and ensure the appropriate use of information.</p>
 
<h2>Links</h2>
<p>Our site may contain links to websites maintained by third parties. While we make every effort to only link to sites that follow proper privacy and security protocols, we cannot be responsible for their privacy and security practices.</p>
 
<h2>Changes to this policy</h2>
<p>Our Privacy Policy is reviewed regularly to ensure that it reflects how we use your information. Any changes will be notified by updating this page.</p>
<p>The policy was last updated on May 18, 2021.</p>
 
<h2>Contacting Us</h2>
<p>If you are a California resident and would like to exercise your rights under the California Consumer Privacy Act (CCPA), please email CCPA@advocatsagency.com</p>
<p>If you are an EU citizen and would like to exercise your rights under the General Data Protection Regulation (GDPR), please email GDPR@advocatsagency.com</p>
<p>For all other questions about this privacy policy, cookies, the practices of this site, or the information we might hold about you, please email help@advocatsagency.com</p>
<p>Our mailing address is:</p>
<p>Advocats Agency<br />
6 Liberty Square #2479<br />
Boston, MA 02109</p>
              </GridItem>
            </GridContainer>
        
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
