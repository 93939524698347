import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import SectionContactUs from "../HomePage/Sections/SectionContactUs";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/workPage.js";

import laptop from "assets/img/work/mv-hcp/laptop.png";
import ipad from "assets/img/work/mv-hcp/iPad-Pro.png";
import longScreens from "assets/img/work/mv-hcp/longscreens-MVhcp.jpg";
import postcard from "assets/img/work/mv-hcp/Postcard.png";
import brochure1 from "assets/img/work/mv-hcp/A4_Brochure_Mockup_1.png";
import brochure2 from "assets/img/work/mv-hcp/A4_Brochure_Mockup_5sm.png";
import headerImg from "assets/img/work/mv-hcp/header.png";


const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const mainClasses = classNames({
    [classes.main]: true,
    [classes.minervaCorp]: true
  });
  const { ...rest } = props;
  
  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax className="minerva-consumer" small image={headerImg}>
        <div className="portfolio-client-name">
          <span>M</span>
          <span>I</span>
          <span>N</span>
          <span>E</span>
          <span>R</span>
          <span>V</span>
          <span>A</span>
        </div>
        <div className="portfolio-title">
          MINERVA HCP
        </div>
      </Parallax>
      <div className={mainClasses}>
        <div>
          <div className={classes.container}>
            <h1 className={classes.title}>Minerva HCP</h1>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={8}>
              <p>Minerva grew like a 12 year old boy after the acquisition of three intrauterine care devices. Wrangling the priorities was intense and fun. What emerged is a change in tone to match the growth spurt, a new brand, corporate & HCP website and resource library stocked with engaging content for a variety of audiences and places.</p>
              </GridItem>
            </GridContainer>
          </div>
          <div className={classes.container}>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={12}>
                <img alt="" src={laptop} />
              </GridItem>
            </GridContainer>
          </div>

          <div className={classes.container} style={{marginTop:'100px'}}>
            <GridContainer justify="center" className="flex-center">
              <GridItem xs={12} sm={12} md={12}>
                <img alt="" src={ipad} />
              </GridItem>
            </GridContainer>
          </div>

          <div className="vertical-holder story top">
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <div className="item longscreens"><img alt="" src={longScreens} /></div>
              </GridContainer>
            </div>
          </div>

          <div className="vertical-holder print">
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <GridItem xs={12} sm={12} md={12}>
                  <img alt="" src={postcard} />
                </GridItem>
              </GridContainer>
            </div>
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <GridItem xs={12} sm={12} md={12}>
                  <img alt="" src={brochure1} />
                </GridItem>
              </GridContainer>
            </div>
            <div className={classes.container} style={{marginTop:'100px'}}>
              <GridContainer justify="center" className="flex-center">
                <GridItem xs={12} sm={12} md={12}>
                  <img alt="" src={brochure2} />
                </GridItem>
              </GridContainer>
            </div>
          </div>
          
          <div className={classes.container}>
            <SectionContactUs />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
