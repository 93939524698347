import React from 'react';
import { NavHashLink as Link } from 'react-router-hash-link';

const MenuItem = ({ itemName,noHash }) => {
  let activeClass = '';
  let toLink = `/#${itemName}`;
  if (window.location.href.indexOf('our-work/') > 0 && itemName === 'work') {
    activeClass = 'selected';
  }
  if (noHash) {
    toLink = `/${itemName}`;
  }

  return (
      <Link
        // smooth
        to={toLink}
        activeClassName="selected"
        className={activeClass}
        scroll={el => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
      >
        {itemName}
      </Link>
  );
};

export default MenuItem;