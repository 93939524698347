import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/components/infoStyle.js";

const useStyles = makeStyles(styles);

export default function InfoArea(props) {
  const classes = useStyles();
  const { title, list, logoO, logoPlus } = props;
  const iconClasses = classNames({
    [classes.logoPlus]: logoPlus,
    [classes.logoO]: logoO
  });

  return (
    <div className={classes.infoArea}>
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title}</h4>
        <div className={iconClasses}>
          <div className={classes.list}>
          {list.map(t => (
            <div key={t} className={classes.listItem}>{t}</div>
          ))}
          </div>
        </div>
      </div>
    </div>
  );
}

InfoArea.propTypes = {
  title: PropTypes.string.isRequired,
  vertical: PropTypes.bool
};
