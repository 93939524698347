import React, { useState } from "react";
// nodejs library that concatenates classes

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import SectionContactUs from "../HomePage/Sections/SectionContactUs.js";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import headerImg from "assets/img/about/header.jpg";
import styles from "assets/jss/material-kit-react/views/aboutStyle";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const mainClasses = classNames({
    [classes.main]: true,
    [classes.about]: true
  });
  const { ...rest } = props;

  const [isJaneActive, setJaneActive] = useState(false);
  const [isCarolActive, setCarolActive] = useState(false);
  const [isXavierActive, setXavierActive] = useState(false);
  const [isBobActive, setBobActive] = useState(false);

  const [isJaneReadMoreActive, setJaneReadMoreActive] = useState(false);
  const [isCarolReadMoreActive, setCarolReadMoreActive] = useState(false);
  const [isXavierReadMoreActive, setXavierReadMoreActive] = useState(false);
  const [isBobReadMoreActive, setBobReadMoreActive] = useState(false);

  const showBio = (e) => {
    if (e.target.id == 'jane') {
      setJaneActive(!isJaneActive);
      setJaneReadMoreActive(false);
      console.log('jane');
    } else if (e.target.id == 'carol') {
      setCarolActive(!isCarolActive);
      setCarolReadMoreActive(false);
      console.log('carol');
    } else if (e.target.id == 'xavier') {
      setXavierActive(!isXavierActive);
      setXavierReadMoreActive(false);
      console.log('xavier');
    } else if (e.target.id == 'bob') {
      setBobActive(!isBobActive);
      setBobReadMoreActive(false);
      console.log('bob');
    }
  }

  const readMore = (e) => {
    if (e.target.id == 'bio_jane') {
      setJaneReadMoreActive(!isJaneReadMoreActive);
    } else if (e.target.id == 'bio_carol') {
      setCarolReadMoreActive(!isCarolReadMoreActive);
    } else if (e.target.id == 'bio_xavier') {
      setXavierReadMoreActive(!isXavierReadMoreActive);
    } else if (e.target.id == 'bio_bob') {
      setBobReadMoreActive(!isBobReadMoreActive);
    }
  }

  
  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax className="about" small image={headerImg}>
        <div className="portfolio-client-name about">
          <span>A</span>
          <span>B</span>
          <span>O</span>
          <span>U</span>
          <span>T</span>
        </div>
      </Parallax>
      <div className={mainClasses}>
        <div className={classes.container}>
          
          <GridContainer justify="flex-start" className="grid-container">
            <GridItem xs={12} sm={12} md={8}>
            <h1 className={classes.title}>Creative brains on a tech backbone</h1>
            <p>After leaving big agencies for all the right reasons, we came together as freelancers. The magic we’d all been seeking throughout our careers was right there. That magic became Advocats–a modern kind of healthcare ad agency. Free of complexity, full of creativity. Tech-forward, value-focused. Dedicated client teams that become your secret advantage. Fully transparent. Excellent value. Responsive. And smart. </p>
            <p>That’s how we create healthy experiences for consumers, patients and healthcare professionals alike. </p>
            </GridItem>
          </GridContainer>
          <GridContainer justify="flex-start" className="grid-container">
            <GridItem xs={12} sm={12} md={8}>
            <h1 className={classes.title}>Meet some Advocats</h1>
            </GridItem>
          </GridContainer>
        

          <GridContainer justify="flex-start" className="grid-container">
            <GridItem xs={12} sm={12} md={12}>
            <div className="about-team">
              <div id="jane" className={isJaneActive ? "team-member jane active": "team-member jane"} onClick={showBio}></div>
              <div className={isJaneActive ? "bio active": "bio"}>
                <div className="bio-wrapper">
                  <div className="intro">
                    <span><strong>Jane's bio:</strong></span>
                    <p>As a Creative Director, Jane has led creative teams at SolarCity, CKS, USWeb/CKS, and Liquid Thinking. An established and innovative designer, Jane has worked with many brands, large and small. </p>
                    <div id="bio_jane" className={isJaneReadMoreActive ? "readmore hide": "readmore"} onClick={readMore}>READ MORE</div>
                  </div>
                  <div className={isJaneReadMoreActive ? "more active": "more"}>
                    <p>Jane is an expert in both brand creation and caretaking. She has recently been working on multiple projects for Boston Scientific and Minerva Surgical.</p>
                    <p>As Sr. Director of Creative Services at SolarCity from 2012 to 2014 Jane was instrumental in elevating the brand to better reflect their position as the market leader in renewable energy for home owners. The company subsequently went public and was acquired by Tesla.</p>
                    <p>Jane was Creative Director &amp; Partner at USWeb/CKS where she led her team's online creations, including Timberland, Fujitsu, MazdaUSA, HP and Clinique. The Clinique site in 1996 was one of the first sites to incorporate database-driven content and personalization. She was also worldwide Creative Director on European accounts, leading teams in Paris, Geneva, Belgium, Cupertino and Seattle.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-team">
            <div id="carol" className={isCarolActive ? "team-member carol active": "team-member carol"} onClick={showBio}></div>
              <div className={isCarolActive ? "bio active": "bio"}>
                <div className="bio-wrapper">
                  <div className="intro">
                  <span><strong>Carol's bio:</strong></span>
                    <p>Writing that emotionally connects clients to their customers. Words that generate confidence. A voice that brings a brand to life. Irresistible pathways whisking customers through simple buying experiences. Distilling the complex into snackable pieces of content that inform and delight. Those are hallmarks of the work Carol Johnson has produced as a Writer/Content Strategist.</p>
                    <div id="bio_carol" className={isCarolReadMoreActive ? "readmore hide": "readmore"} onClick={readMore}>READ MORE</div>
                  </div>
                  <div className={isCarolReadMoreActive ? "more active": "more"}>
                    <p>Carol's clients include: British Technology Group, Minerva, Acessa, Boston Scientific, Novartis, EndoGastric Solutions, Panasonic, Canon, Philadelphia Eagles, ESPN, Sirius/XM Radio, Comcast/NBC and AOL, as well as Physician Practice Groups and Hospital Systems.</p>
                    <p>Formats include websites, interactive experiences, landing pages, webinars, email lead nurturing sequences, social media content and ads, surveys & quizzes, radio, podcast and video scripts and ads, and traditional print pieces.</p>
                    <p>From launching a brand and wrapping it in persuasive content, to targeted educational and conversion experiences for patients grappling with difficult health challenges, Carol helps you build a rock solid relationship with your target audiences, word by word.</p>
                  </div>
                </div>
              </div>
            </div>
            </GridItem>
          </GridContainer>

          <GridContainer justify="flex-start">
            <GridItem xs={12} sm={12} md={12}>
            <div className="about-team">
              <div id="xavier" className={isXavierActive ? "team-member xavier active": "team-member xavier"} onClick={showBio}></div>
              <div className={isXavierActive ? "bio active": "bio"}>
                <div className="bio-wrapper">
                  <div className="intro">
                  <span><strong>Xavier's bio:</strong></span>
                    <p>Xavier has been involved in the SF Bay Area Internet industry since 1998. He has led several large web teams at USWeb/CKS, March First, and Phoenix Pop / Liquid Thinking during the early 2000’s.</p>
                    <div id="bio_xavier" className={isXavierReadMoreActive ? "readmore hide": "readmore"} onClick={readMore}>READ MORE</div>
                  </div>
                  <div className={isXavierReadMoreActive ? "more active": "more"}>
                    <p>As an experienced full-stack software engineer he has architected and led many enterprise level projects with teams scattered across the globe.</p>
                    <p>Since 2007, he has been involved in the Drupal community, working as a senior developer and architect with various companies in the US and Europe.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-team">
            <div id="bob" className={isBobActive ? "team-member bob active": "team-member bob"} onClick={showBio}></div>
              <div className={isBobActive ? "bio active": "bio"}>
                <div className="bio-wrapper">
                  <div className="intro">
                  <span><strong>Bob's bio:</strong></span>
                    <p>Bob keeps his eye on all the moving pieces. He brings three decades of managing a rapidly-growing
consumer products ad agency that supports major players like Panasonic, Canon and LG.</p>
                    <div id="bio_bob" className={isBobReadMoreActive ? "readmore hide": "readmore"} onClick={readMore}>READ MORE</div>
                  </div>
                  <div className={isBobReadMoreActive ? "more active": "more"}>
                    <p>His strengths–orchestrating production over many time zones and time crunches and managing client projects so they get the
most value for their money–help keep Advocats on schedule, on budget
and on our toes.</p>
                    <p>Bob also serves as the lead on print &amp; special offline projects for
engaging live event experiences.</p>
                  </div>
                </div>
              </div>
            </div>
        
            </GridItem>
          </GridContainer>

        </div>
          
          
          
        <div className={classes.container}>
          <SectionContactUs />
        </div>
      </div>
      
      <Footer />
    </div>
  );
}
