import React, { useState } from 'react'
import emailjs from '@emailjs/browser';

import ReactHtmlParser from 'react-html-parser';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import contactUsStyle from "assets/jss/material-kit-react/views/landingPageSections/contactUsStyle.js";
import { TextareaAutosize } from '@material-ui/core';
const useStyles = makeStyles(contactUsStyle);

export default function SectionContactUs() {
  const classes = useStyles();

  const [name, setName] = useState([]);
  const [nameAlert, setNameAlert] = useState([]);
  const [org, setOrg] = useState([]);
	const [email, setEmail] = useState([]);
	const [phone, setPhone] = useState([]);
	const [message, setMessage] = useState([]);
	const [successMessage, setSuccessMessage] = useState(false);

	const nameChange = (event) => {
    setName(event.target.value)
  }
  const orgChange = (event) => {
    setOrg(event.target.value)
	}
	const emailChange = (event) => {
		setEmail(event.target.value)
	}
	const phoneChange = (event) => {
		setPhone(event.target.value)
	}
	const messageChange = (event) => {
		setMessage(event.target.value)
	}

	//onSubmit of email form
	const handleSubmit = (event) => {
    event.preventDefault();
    setNameAlert(name);

		let templateParams = {
      from_email: email,
      from_name: name,
      from_org: org,
      to_name: "Team Advocats",
      from_phone: phone,
      message: message,
      reply_to: "xavier@advocatsagency.com",
     }
     emailjs.send(
      'service_pd2rhwi',
      'template_d5a8ucj',
       templateParams,
      'mdLPu-zelJpRZNvst'
     )
     resetForm();
  }
  
  const resetForm = () => {
    setName('');
    setEmail('');
    setOrg('');
    setPhone('');
    setMessage('');
    setSuccessMessage(true);
  }

  const successMessageText = '<div class="snackbar-alert">Nice to see you, <b>' + nameAlert + '</b><br />We’ll be in touch within a day</div>'

  const successMessageDisplay = successMessage ? <SnackbarContent
      message={ReactHtmlParser(successMessageText)}
      close
      color="success"
      icon={Check}
      className="snackbar"
    /> : '';

  return (
    <div className={classes.main} id="contact">
      <div className={classes.contactContent}>
        <div className={classes.container}>
          <h1 className={classes.title}>Be the center of our world.</h1>
          {successMessageDisplay}
          <GridContainer className={classes.containerDescription}>
            <GridItem xs={12} sm={12} md={8}>
              <p>
              With no fancy facade to polish and shine, you{"'"}ll 
              get the time and attention you deserve from a veteran team actually doing the work. 
              <br /><strong>Let{"'"}s connect.</strong>
              </p>
              <form onSubmit={handleSubmit}>
                <CustomInput
                  labelText="Your Name"
                  id="float"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: name,
                    onChange: nameChange,
                    required: true,
                  }}
                />
                <CustomInput
                  labelText="Organization"
                  id="float"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: org,
                    onChange: orgChange
                  }}
                />
                <CustomInput
                  labelText="Email address"
                  id="float"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: email,
                    onChange: emailChange,
                    required: true,
                    type: 'email'
                  }}
                />
                <CustomInput
                  labelText="Phone"
                  id="float"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: phone,
                    onChange: phoneChange
                  }}
                />
                <CustomInput
                  labelText="How can we assist?"
                  id="float"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 6,
                    value: message,
                    onChange: messageChange,
                    required: true,
                  }}
                  
                />
                <div>
                  <Button type="submit" color="primary">
                    Contact us
                  </Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>

        </div>
      </div>
    </div>
  );
}
