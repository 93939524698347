import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  title
} from "assets/jss/material-kit-react.js";

import logoO from "assets/img/logo-o.png";
import logoPlus from "assets/img/logo-plus.png";

const infoStyle = {
  infoArea: {
    maxWidth: "200px",
    margin: "0 auto",
    padding: "0px"
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px"
  },
  primary: {
    color: primaryColor
  },
  warning: {
    color: warningColor
  },
  danger: {
    color: dangerColor
  },
  success: {
    color: successColor
  },
  info: {
    color: infoColor
  },
  rose: {
    color: roseColor
  },
  gray: {
    color: grayColor
  },
  icon: {
    width: "36px",
    height: "36px"
  },
  descriptionWrapper: {
    color: grayColor,
    overflow: "hidden"
  },
  title: {
    ...title,
    fontSize: "26px",
    textAlign: "center",
    fontFamily: "Lato",
    fontWeight: "900",
  },
  description: {
    color: grayColor,
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "14px"
  },
  iconWrapperVertical: {
    float: "none"
  },
  iconVertical: {
    width: "61px",
    height: "61px"
  },
  list: {
    color: "#484848",
    fontSize: "18px",
    lineHeight: "34px",
    fontWeight: "700",
    textAlign: "center",
    width: "200px",
    "& > div": {
      padding: "3px 0",
    }
  },
  logoPlus: {
    backgroundImage: "url(" + logoPlus + ")",
    backgroundPosition: "top center",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    minWidth: "200px",
    minHeight: "200px"
  },
  logoO: {
    backgroundImage: "url(" + logoO + ")",
    backgroundPosition: "top center",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    minWidth: "200px",
    minHeight: "200px"
  }
};

export default infoStyle;
