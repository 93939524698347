/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

import FooterLogo from 'assets/img/logo.svg';

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer>
      <div className={classes.container}>
        <div className="footer footer-links">
          <ul>
            <li>
              <Link
                to="/"
                className={classes.block}
              >
                <img src={FooterLogo} />
              </Link>
            </li>
            <li>
              <a
                href="mailto:hello@advocatsagency.com"
                className="break-text"
              >
                HELLO@ ADVOCATSAGENCY.COM
              </a>
            </li>
            <li>
              <address>
                6 Liberty Square #2476<br/>
                Boston, MA 02109
              </address>
            </li>
          </ul>
        </div>
        <div className="footer copyright">
          <ul>
            <li>
              <Link to="/terms" className="sep">Terms of use</Link>
              <Link to="/privacy">Privacy</Link>
            </li>
            <li>
            &copy; {1900 + new Date().getYear()},{" "}
              Advcocats Agency, LLC
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
