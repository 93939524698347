import React from "react";
// nodejs library that concatenates classes

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/workPage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const mainClasses = classNames({
    [classes.main]: true,
    [classes.acessa]: true
  });
  const { ...rest } = props;
  
  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax className="level3" level3 image={require("assets/img/level3/header.jpg")}>
        
      </Parallax>
      <div className={`${mainClasses} ${"padding-bottom-big content-container"}`}>
        <div className="margin-bottom-big">
          <div className={classes.container}>
            <h1 className={classes.title}>Terms of Use</h1>
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={12} md={8}>
              <p>Last Modified: May 18, 2021</p>
              <h2>Acceptance of the Terms of Use</h2>
              <p>These terms of use are entered into by and between You and Advocats, LLC, a Massachussets limited liability company (“Company”, “we” or “us”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Use”), govern your access to and use of www.advocatsagency.com, including any content, functionality and services offered on or through the website (the “Website”).</p>
              <p>Please read the Terms of Use carefully before you start to use the Website. </p>
              <p>By using the Website or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website.</p>
              <p>This Website is offered and available to users who are 18 years of age or older. By using this Website, you represent and warrant that you are of legal age. If you are not 18 years of age or older, you must not access or use the Website.</p>
              <h4>Advocats Agency does not practice medicine or provide medical services or advice and the information on this Site and social media sites operated by Advocats Agency should not be considered medical advice. Do not consult this Site in a medical emergency.</h4>
              <h2>Changes to the Terms of Use</h2>
              <p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Website thereafter. However, any changes to the dispute resolution provisions set forth in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or prior to the date the change is posted on the Website.</p>

              <p>Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</p>

<h2>Account Security</h2>

<p>To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current and complete. You agree that all information you provide to register with this Website or otherwise, including but not limited to through the use of any interactive features on the Website, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>

<h2>Intellectual Property Rights</h2>

<p>The Website and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</p>

<p>These Terms of Use permit you to use the Website for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Website, except as follows:</p>

<ul>
  <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
  <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
  <li>You may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication or distribution.</li>
  <li>If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</li>
  <li>If we provide social media features with certain content, you may take such actions as are enabled by such features.</li>
</ul>

<p>You must not:</p>

<ul>
  <li>Modify copies of any materials from the Website.</li>
  <li>Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.</li>
  <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from the Website.</li>
</ul>

<p>You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.</p>

<p>If you wish to make any use of material on the Website other than that set out in this section, please address your request to: help@advocatsagency.com.</p>

<p>If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>

<h2>Trademarks</h2>

<p>The Company name, the Company logo and all related names, logos, product and service names, designs and slogans are trademarks of the Company. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on this Website are the trademarks of their respective owners.</p>

<h2>Prohibited Uses</h2>

<p>You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website:</p>

<ul>
  <li>In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</li>
  <li>To send, transmit, or otherwise provide, any advertising or promotional material, including without limitation any image file, video file, demo, “junk mail”, “chain letter” or “spam” or any other similar solicitation.</li>
  <li>To impersonate or attempt to impersonate Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing).</li>
  <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Website, or which, as determined by us, may harm Company or users of the Website or expose them to liability.</li>
</ul>

<p>Additionally, you agree not to:</p>

<ul>
  <li>Use the Website in any manner that could disable, overburden, damage, or impair the Website or interfere with any other party’s use of the Website, including their ability to engage in real time activities through the Website.</li>
  <li>Use any robot, spider or other automatic device, process or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</li>
  <li>Use any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without our prior written consent.</li>
  <li>Use any device, software or routine that interferes with the proper working of the Website.</li>
  <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li>
  <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer or database connected to the Website.</li>
  <li>Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</li>
  <li>Otherwise attempt to interfere with the proper working of the Website.</li>
</ul>

<h2>Information About You and Your Visits to the Website</h2>

<p>All information we collect on this Website is subject to our Privacy Policy. By using the Website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>

<h2>Other Terms and Conditions</h2>

<p>Additional terms and conditions may also apply to specific portions, services or features of the Website. All such additional terms and conditions are hereby incorporated by this reference into these Terms of Use.</p>

<h2>Linking to the Website and Social Media Features</h2>

<p>This Website may provide certain social media features that enable you to:</p>

<ul>
  <li>Link from your own or certain third-party websites to certain content on this Website.</li>
  <li>Send e-mails or other communications with certain content, or links to certain content, on this Website.</li>
  <li>Cause limited portions of content on this Website to be displayed or appear to be displayed on your own or certain third-party websites.</li>
</ul>

<p>You may use these features solely as they are provided by us, solely with respect to the content they are displayed with and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:</p>

<ul>
  <li>Establish a link from any website that is not owned by you.</li>
  <li>Cause the Website or portions of it to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site.</li>
  <li>Otherwise take any action with respect to the materials on this Website that is inconsistent with any other provision of these Terms of Use.</li>
</ul>


<p>You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice. We may disable all or any social media features and any links at any time without notice in our discretion.</p>

<h2>Links from the Website</h2>

<p>If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third party websites linked to this Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</p>

<h2>Geographic Restrictions</h2>

<p>The owner of the Website is based in the state of Masssachussetes in the United States. We make no claims that the Website or any of its content is accessible or appropriate outside of the United States. Access to the Website may not be legal by certain persons or in certain countries. If you access the Website from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>

<h2>Disclaimer of Warranties</h2>

<p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing procedures and security checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>

<p>YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>

<p>COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.</p>

<p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>

<h2>Limitation on Liability</h2>

<p>IN NO EVENT WILL COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.</p>

<p>IF YOU ARE A CALIFORNIA RESIDENT OR A RESIDENT OF A STATE WITH A SIMILAR STATUTE, YOU WAIVE CALIFORNIA CIVIL CODE §1542 OR SUCH OTHER STATE STATUTE (AS APPLICABLE), WHICH SAYS: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.</p>

<p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>

<h2>Indemnification</h2>

<p>You agree to defend, indemnify and hold harmless Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, your use of the Website’s content, services and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Website.</p>

<h2>Governing Law and Jurisdiction</h2>

<p>Except as set forth in the Disputes; Mandatory Arbitration and Class Action Waiver Section immediately below, all matters relating to the Website and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Massachussets without giving effect to any choice or conflict of law provision or rule (whether of the State of Massachussets or any other jurisdiction).</p>

<h2>Disputes; Mandatory Arbitration and Class Action Waiver</h2>

<p>Any disputes or claims relating in any way to your use of the Website, or to products or services sold or distributed by Company or through us, will be resolved by binding arbitration rather than in court, with the following exceptions:</p>
<ul>
  <li>you may assert claims in small claims court if your claims apply; and</li>
  <li>in the event that the arbitration agreement in these Terms of Use is for any reason held to be unenforceable, any litigation against Company (except for small-claims court actions) may be commenced only in a federal or state court located within Boston, Massachussets, and we both consent to the jurisdiction of those courts for such purposes. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</li>
</ul>



<p>The arbitration agreement in these Terms of Use is governed by the Federal Arbitration Act (the “FAA”), including its procedural provisions, in all respects.  </p>

<p>This arbitration agreement is intended to be broadly interpreted and will survive termination of these Terms of Use. </p>

<h2>Waiver and Severability</h2>

<p>No waiver of by Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>

<p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>
 
<h2>Entire Agreement</h2>

<p>The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and Company with respect to the Website and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Website.</p>

<h2>Your Comments and Concerns</h2>

<p>This website is operated by Advocats Agency, 6 Liberty Square #2479
Boston, MA 02109. All other feedback, comments, requests for technical support and other communications relating to the Website should be directed to: help@advocatsagency.com.</p>

              </GridItem>
            </GridContainer>
          

           

          </div>
          
          
          
        </div>
      </div>
      <Footer />
    </div>
  );
}
