import React, { Component } from 'react';
import { Player, BigPlayButton } from 'video-react';
import thumbnail from 'assets/img/work/aub/video-ident-still.jpg'
import { AUB_VIDEO_URL } from 'utils/StaticContent';
const sources = {
    introVideo: AUB_VIDEO_URL
};

export default class VideoPlayer extends Component {


    render() {
        return (
            <Player
                poster={thumbnail}
                src={sources.introVideo}
            >
                <BigPlayButton position="center" />
            </Player>

        );
    }
}